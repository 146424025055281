
/* Default styles */
body {
  margin: auto;
}

.headingAndSearch {
  margin: 30px;
}

.myHeading {
  margin-bottom: 20px;
}

.button-standard{
  background-color: #d2ac49 !important;
  border: #d2ac49 !important;
  color: white !important;
}

.button-standard:hover{
  background-color: #96814d !important;
  border: #96814d !important;
}

.searchButton {
  margin: 0 5px;
  background-color: #d2ac49 !important;
  color: #fff !important;
  border: none !important;
}

.new-message-indicator {
  color: red;
  font-weight: bold;
}

.myLogIn {
  padding: 50px;
}

.pagination-buttons {
  margin: 30px;
}

.list-group-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
}

.page-item {
  margin: 0 2px;
  padding: 6px 10px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.page-item:hover {
  background-color: #ddd;
}

.page-item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.page-item.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .headingAndSearch {
    margin: 20px;
  }

  .myHeading {
    margin-bottom: 15px;
  }

  .searchButton {
    margin: 0 3px;
  }

  .myLogIn {
    padding: 30px;
  }

  .pagination-buttons {
    margin: 20px;
  }

  .page-item {
    margin: 0 1px;
    padding: 4px 8px;
    font-size: 10px;
  }
}
